<template>
  <div>
    <h3 class="font-weight-medium">
      {{ $t('views.Withdraw.confirm.desc', {
        amount: $n(amount, 'floatCurrency')
      }) }}
    </h3>
    <CreditCard :number="user.financialData.refBankIban.replace(/\s/g, '').slice(-4)" class="mt-5" />
    <v-btn
      color="primary"
      class="black--text"
      @click="next"
      :loading="loading"
    >
      {{ $t('views.Withdraw.confirm.btn') }}
    </v-btn>
    <p class="grey--text mt-5">
      {{ $t('views.Withdraw.confirm.info') }}
    </p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyload from '@/utils/lazyLoad';
import { triggerPayout } from '@/api/users';

export default {
  name: 'Confirm',
  components: {
    CreditCard: lazyload('components/Shared/Cards/CreditCard'),
  },
  computed: {
    ...mapGetters('withdraw', [
      'amount',
    ]),
    ...mapGetters('user', [
      'user',
    ]),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions('withdraw', [
      'nextStep',
    ]),
    async next() {
      this.loading = true;
      const input = {
        amount: this.amount,
      };
      try {
        await triggerPayout(input);
        this.nextStep();
      } catch (error) {
        this.$notify.error(error);
      }
      this.loading = false;
    },
  },
};
</script>
